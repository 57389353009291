/** path */
.path {
    position: relative;
    z-index: 1;
    font-family: 'roboto';
    ul { margin: 0 -5px; }
    li {
        position: relative;
        padding: 5px 5px 5px 20px;
        &:before {
            @extend %before-half;
            left: 3px;
            border: solid #666;
            border-width: 0 1px 1px 0;
            padding: 2px;
            transform: translate(0, -50%) rotate(-45deg);
        }
        &:first-child {
            padding: 5px;
            &:before { content: none; }
        }
    }
    a {
        @include hover { color: $c1; }
        @extend %hover-link;
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {}