/** nav */
.nav-title {
    position: relative;
    cursor: pointer;
}
.toggle-nav {
    margin:0 auto;
    transition: all .3s;
    li { overflow: hidden; }
    .nav-list {
        transition: all .3s;
        display: none;
    }
    .sub-nav { display: none; }
    &.on {
        .nav-list { display: block; }
    }
    & + .js-nav { margin: 10px auto 0; }
}
.toggle-node {
    a { flex: 1; }
}
.main-nav {
    li { border-bottom: 1px solid rgba(255, 255, 255, .5); }
    a {
        position: relative;
        display: block;
        padding: 15px 20px;
        text-decoration: none;
    }
    .sub-nav {
        display: none;
        z-index: 1;
    }
    .sub2 {
        background-color: $c1;
        li { border: 0 none; }
        a { padding: 12px 30px; }
    }
    .sub3 {
        background-color: #ddd;
        a { padding: 10px; }
        li + li { border-top: 1px solid #e5e5e5; }
    }
    .node3 {
        color: $c1;
        padding: 0 25px;
    }
    .on {
        > .toggle-node .btn:before {
            transform: translate(-50%, -50%) rotate(-135deg);
        }
        > .sub-nav { display: block; }
    }
    .node > .on { background-color: #333; }
}
.ft-nav {
    @include font(16, 18);
    color: #393B3F;
    a { display: inline-block; }
    .node > li { margin: 0 0 30px; }
    .node2 {
        @include font(14, 16);
        margin: 0 -20px;
        li { padding: 4px 20px; }
    }
    .sub2 { margin-top: 10px; }
}
.nav-t1, .nav-t2 {
    width: 100%;
    padding: 10px 30px 10px 10px;
    outline: 0 none;
    z-index:0;
    &:after {
        @include filter-cw;
        content:''; display:block;
        position:absolute;
        right: 10px; top:50%;
        transform: translate(0, -50%);
        transition: all .3s;
        width: 12px; height: 12px;
        background-image: url(../images/svg/icon-plus-c1.svg);
    }
    &.on {
        color: $c1;
        border-color: $cw;
        background-color: $cw;
        &:after {
            height: 2px;
            background-color: $c1;
            filter: none;
        }
    }
}
.nav-t2 {
    &:after { @include filter-cw; }
    &.on:after { filter: none; }
}
.nav-cate1 {
    @include font(16, 18);
    &.on {
        .nav-title { @include radius(5px 5px 0 0); }
    }
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .nav-cate1 {
        .nav-title { display: none; }
        .nav-list { display: block; }
    }
}
@media screen and (min-width: 769px) {
    .toggle-nav + .js-nav { display: none; }
    .ft-nav {
        .node { display: flex; }
        .node2 {
            margin: 0;
            display: block;
            li { padding: 5px 0; }
        }
        .sub2 { margin-top: 14px; }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1200px) {
    .main-nav {
        a { padding: 25px 0; }
        li:hover {
            > .sub-nav {
                display: block;
                animation: fadeIn .3s;
            }
        }
        .sub-nav {
            display: none;
            position: absolute;
            padding: 0;
            background-color: #fff;
            box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
            left: 50%;
            a { padding: 15px 25px; }
            li + li { border-top: 1px solid #e2e2e2; }
        }
        .sub2 {
            top: 100%;
            min-width: 180px;
            transform: translate(-50%, 0);
        }
        .sub3 {
            min-width: 330px;
            left: 100%; top: 0;
            transform: none;
        }
        .on {
            .sub-nav { display: none; }
        }
        .node {
            display: flex;
            margin: 0 -12px;
            > li {
                position: relative;
                padding: 0 12px;
                &.on {
                    background: none;
                    > a, > .toggle-node a {
                        color: $c1;
                    }
                }
            }
        }
        .node2 {
            > li:hover {
                > a, > .toggle-node a {
                    color: $cw;
                    background-color: $c1;
                }
            }
        }
        .node3 {
            padding: 0;
            color: inherit;
        }
    }
}
@media screen and (min-width: 1400px) {
    .main-nav {
        .node {
            margin: 0 -20px;
            > li {
                padding: 0 20px;
                > a, > .toggle-node a { padding: 30px 0; }
            }
        }
    }
}
@media screen and (min-width: 1600px) {}