/** social */
.social-wrap {
    overflow: hidden;
    ul { margin: 0 -5px; }
    li {
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
    li, a {
        position:relative;
        overflow:hidden;
    }
    a, .btn {
        @include no-text;
        @include radius(50%);
        position: relative;
        display:block;
        width: 34px; height: 34px;
        outline: 0;
        &:before {
            @include abs_half;
            @extend %bg-contain;
            content: ''; display: block;
            width: 60%; height: 60%;
        }
    }
    .label { padding-right: 15px; }
}
.social-list {
    @extend %social-icon;
    a {
        &:before {
            @include radius(50%);
            @extend %bg-contain;
            content: ''; display: inline-block;
            vertical-align: middle;
            width: 21px; height: 21px;
            margin-right: 8px;
            background-color: #000;
            background-size: 70% auto;
        }
        @include hover(true) {
            &:before { background-color: $c1; }
        }
    }
    .icon-mail {
        &:before { background-size: 60% auto; }
    }
}
.social01 {
    @extend %social-icon;
    a, .btn {
        &:before { @include filter-cw; }
        @include radius(5px);
    }
    .icon-mail {
        &:before { width: 60%; height: 60%; }
        @include hover(true) { background: #2BB3C8; }
    }
    .icon-messenger {
        @include hover(true) { background: #26C9FF; }
    }
    .icon-facebook {
        @include hover(true) { background: #3B5998; }
    }
    .icon-instagram {
        @include hover(true) { background: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); }
    }
    .btn-copy {
        @include hover(true) { background: #EBEBEB; }
    }
    .icon-in, .icon-linkedin {
        @include hover(true) { background: #007BB5; }
    }
    .icon-line {
        @include hover(true) { background: #00B900; }
    }
    .icon-pinterest {
        @include hover(true) { background: #e60023; }
    }
    .icon-plurk {
        @include hover(true) { background: #ff574d; }
    }
    .icon-qq {
        @include hover(true) { background: #000; }
    }
    .icon-skype {
        @include hover(true) { background: #01A7FF; }
    }
    .icon-sina {
        @include hover(true) { background: #E6162D; }
    }
    .icon-twitter {
        @include hover(true) { background: #1d9bf0; }
    }
    .icon-ubereat {
        @include hover(true) { background: #85B200; }
    }
    .icon-whatsapp {
        @include hover(true) { background: #00e676; }
    }
    .icon-wechat {
        @include hover(true) { background: #00C300; }
    }
    .icon-youtube2 {
        &:before { width: 55%; height: 55%; }
        @include hover(true) { background: #EC162C; }
    }
    .icon-youtube {
        @include hover(true) { background: #EC162C; }
    }
    .icon-tel {
        @include hover(true) { background: #056CAE; }
    }
    .icon-rss {
        @include hover(true) { background: #f7770a; }
    }
}
.social02 {
    @extend %social-icon;
    a, .btn {
        &:before {
            @include filter-cw;
            width: 60%; height: 60%;
        }
    }
    .icon-mail {
        background: #2BB3C8;
        &:before { width: 45%; height: 45%; }
    }
    .icon-messenger {
        background: #26C9FF;
    }
    .icon-facebook {
        background: #3A559F;
    }
    .icon-instagram {
        background: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    }
    .btn-copy {
        background: #EBEBEB;
        
    }
    .icon-in, .icon-linkedin {
        background: #007BB5;
    }
    .icon-line {
        background: #00B900;
        
    }
    .icon-pinterest {
        background: #e60023;
        
    }
    .icon-plurk {
        background: #ff574d;
    }
    .icon-qq {
        background: #000;
        
    }
    .icon-skype {
        background: #01A7FF;
        
    }
    .icon-sina {
        background: #E6162D;
        
    }
    .icon-twitter {
        background: #1DA1F2;
        
    }
    .icon-ubereat {
        background: #85B200;
        
    }
    .icon-whatsapp {
        background: #00e676;
        
    }
    .icon-wechat {
        background: #00C300;
        
    }
    .icon-youtube2 {
        background: #EC162C;
        
    }
    .icon-youtube {
        background: #EC162C;
        &:before { width: 55%; height: 55%; }
    }
    .icon-tel {
        background: #056CAE;
    }
    .icon-rss {
        background: #f7770a;
    }
}
.social03 {
    @extend %social-icon;
    a, .btn {
        border: 1px solid $c1;
        @include hover(true) {
            background-color: $c1;
            &:before { filter: saturate(0) brightness(20); }
        }
    }
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
    .social01 {
        a, .btn { width: 36px; height: 36px; }
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1400px) {}