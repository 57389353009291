.hd-tp {
    @include font(16);
    position: sticky;
    left: 0; top: 0;
    width: 100%;
    min-width: 320px;
    margin:0 auto;
    background: #fff;
    box-shadow: 0 6px 4px rgba(#c8c8c8, .16);
    z-index: 999;
    transition: all .3s;
    .btn-search1 { display: none; }
}
.hd-row {
    position: relative;
    padding: 0 20px;
    height: 80px;
    z-index: 1;
}
.hd-sec { align-self: center; }
.hd-s2 {
    position: absolute;
    top: 100%; left: -100%;
    width: 100%;
    max-height: calc(100vh - 80px);
    transition: all .3s;
    overflow-y: auto;
    &.on { left:0; }
}
.hd-s3 { margin-left: auto; }
.hd-i3 {
    position: relative;
    z-index: 0;
    &:before {
        @include abs_full;
        @extend %bg-linear;
        content: ''; display: block;
        z-index: -1;
    }
}
.logo {
    position: relative;
    width: 140px;
    margin: 0 auto;
    transition: all .3s;
    z-index: 1;
    a, img { display: block; }
}
@media screen and (min-width: 375px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1200px) {
    .hd-tp {
        padding: 0 0 0 20px;
        .btn-search1 { display: block; }
        .form-wrap {
            display: none;
            position: absolute;
            right: 0; top: 100%;
            padding: 20px;
            width: 400px;
            background: #fff;
            box-shadow: 0 0 5px rgba(0, 0, 0, .2);
            z-index: 1;
            &.on { display: block; }
        }
    }
    .hd-row {
        color: #444;
        padding: 0;
        height: auto;
    }
    .hd-s2 {
        display: flex;
        position: static;
        width: auto;
        margin-left: auto;
        left: 0; top: 0;
        padding: 0;
        max-height: none;
        color: inherit;
        overflow-y: visible;
        transition: none;
    }
    .hd-s3 { display: none; }
    .hd-i2 { padding: 0 0 0 30px; }
    .hd-i3 {
        padding: 30px 20px 10px 65px;
        &:before {
            bottom: -15px;
            right: 0;
            width: auto; height: auto;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 65px 100%);
        }
    }
}
@media screen and (min-width: 1400px) {
    .logo { width: auto; }
    .hd-i2 { padding: 0 10px 0 40px; }
}
@media screen and (min-width: 1600px) {
    .hd-tp { @include font(20); }
    .hd-i2 { padding: 0 20px 0 60px; }
}
@media screen and (min-width: 1900px) {
    .hd-tp { padding-left: 3%; }
    .hd-i3 {
        padding: 30px 35px 10px 85px;
        &:before {
            bottom: -15px;
            right: 0;
            width: auto; height: auto;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 65px 100%);
        }
    }
}