/** api-wrap */
.api-wrap {
    position:relative;
    height:0;
    padding: 0 0 56.25%;
    overflow: hidden;
    iframe, object, embed, video { @include abs_full; }
}
.api-map { height: 300px; }
.tab-item {
    display: none;
    &.on {
        display: block;
        animation: fadeInRight .5s;
    }
}
.scroll-1 {
    > .mCustomScrollBox {
        > .mCSB_scrollTools {
            @include radius(10px);
            width: 100%; height: 6px;
            background-color: #B0B5BC;
            .mCSB_dragger {
                @include radius(10px);
                .mCSB_dragger_bar {
                    background: $c1;
                    height: 6px;
                    margin: 0 auto;
                }
            }
            .mCSB_draggerRail { background-color: #B0B5BC; }
        }
    }
}
.scroll-2 {
    max-height: 360px;
    > .mCustomScrollBox {
        > .mCSB_scrollTools {
            @include radius(30px);
            width: 6px; height: 100%;
            background-color: #f1f1f1;
            .mCSB_dragger {
                @include radius(30px);
                .mCSB_dragger_bar {
                    width: 6px;
                    background: #929292;
                }
            }
            .mCSB_draggerRail { background-color: #f1f1f1; }
        }
    }
}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .g-map { padding: 0; }
}
@media screen and (min-width: 769px) {
    .api-map {
        height: 100%;
        padding: 0;
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1400px) {}