%block { display: block; }
%m0p0 { margin: 0; padding: 0; }
%m0-auto { margin: 0 auto; }
%table-wrap {
    overflow-x:auto;
    margin: 0 auto;
    table { border-collapse: collapse; }
    th, td {
        padding: 8px 5px;
        background-color: #fff;
    }
    th { font-weight: 400; }
    [align="left"] { text-align: left; }
    [align="right"] { text-align: right; }
    [align="center"] { text-align: center; }
}
%list-wrap {
    ul, li, dl, dt, dd { @extend %m0p0; }
    ul { list-style: none outside none; }
}
%list {
    @extend %m0p0;
    list-style: none outside none;
    li, dt, dd { @extend %m0p0; }
}
%before-half {
    content: ''; display: block;
    position: absolute;
    left: 0; top: 50%;
    transform: translate(0, -50%);
}
%form-ctrl {
    @include radius(0);
    display: block;
    width: 100%;
    min-width: 150px;
    padding: 8px 15px;
    background: #ffffff;
    border: 1px solid #ddd;
    line-height: 1.5;
    transition: border-color .3s;
}
%btn-base, %btn-m, %btn-s {
    @include hover { text-decoration: none; }
    position: relative;
    display:inline-block;
    vertical-align: middle;
    color: inherit;
    width: 100%;
    text-align: center;
    text-decoration:none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    transition: all .3s;
    line-height: normal;
    font-weight: 400;
    &:before, &:after { @extend %bg-contain; }
}
%btn-arrow-r {
    &:after {
        content: ''; display: inline-block;
        vertical-align: middle;
        width: 12px; height: 12px;
    }
}
%black { color: $black; }
%white { color: $white; }
%red { color: $red; }
%orange { color: $orange; }
%blue { color: $blue; }
%green { color: $green; }
@each $name, $color in $colors {
    %#{$name} { color: var(--#{$name}); }
    %bg#{$name} { background-color: var(--#{$name}); }
    %hover-#{$name} {
        @include hover-c(var(--#{$name}));
    }
    %hover-bg#{$name}{
        @include hover-bgc(var(--#{$name}));
    }
    %hover-bd#{$name}{
        @include hover-bdc(var(--#{$name}));
    }
}
%bgb { background-color: $base; }
%bgc-lite1 { background-color: $lite1; }
%hover-link {
    text-decoration: none;
    @include hover { text-decoration: underline; }
}
%bg {
    background-position: center;
    background-repeat: no-repeat;
}
%bg-repeat {
    background-position: center;
    background-repeat: repeat;
}
%bg-contain {
    @extend %bg;
    background-size: contain;
}
%bg-cover {
    @extend %bg;
    background-size: cover;
}
%bg-fixed { background-attachment: fixed; }
%clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}
%bg-linear { background: linear-gradient(135deg,  rgba(89,51,152,1) 0%,rgba(45,46,124,1) 100%); }
%bg-linear2 { background: linear-gradient(to top,  rgba(89,51,152,1) 0%,rgba(45,46,124,1) 100%); }
%bg-linear3 { background: linear-gradient(135deg,  rgba(89,51,152,1) 40%,rgba(45,46,124,1) 100%); }
%hover-line {
    text-decoration: none;
    @include hover { text-decoration: underline; }
}
%pre-dash {
    position: relative;
    padding: 0 0 0 25px;
    &:before {
        content: ''; display: block;
        position: absolute;
        left: 0; top: 12px;
        width: 11px; height: 4px;
        background: #bbb;
    }
}
%pre-dot {
    position: relative;
    padding: 0 0 0 15px;
    text-indent: -15px;
    &:before {
        content: ''; display: inline-block;
        vertical-align: middle;
        width: 5px; height: 5px;
        margin: -4px 9px 0 0;
    }
}
%ul-list {
    list-style: none;
    margin: 0; padding: 0;
}
%dl-list {
    margin: 0; padding: 0;
}
%social-icon {
    .icon-mail:before { background-image: url(../images/svg/social-mail.svg); }
    .icon-messenger:before { background-image: url(../images/svg/social-messenger.svg); }
    .icon-facebook:before { background-image: url(../images/svg/social-fb.svg); }
    .icon-instagram:before { background-image: url(../images/svg/social-ig.svg); }
    .icon-copy:before { background-image: url(../images/svg/icon-link.svg); }
    .icon-linkedin:before, .icon-in:before { background-image: url(../images/svg/social-in.svg); }
    .icon-line:before { background-image: url(../images/svg/social-line.svg); }
    .icon-pinterest:before { background-image: url(../images/svg/social-pe.svg); }
    .icon-plurk:before { background-image: url(../images/svg/social-pl2.svg); }
    .icon-qq:before { background-image: url(../images/svg/social-qq.svg); }
    .icon-skype:before { background-image: url(../images/svg/social-sk.svg); }
    .icon-sina:before { background-image: url(../images/svg/social-sn.svg); }
    .icon-twitter:before { background-image: url(../images/svg/social-tw-x.svg); }
    .icon-ubereat:before { background-image: url(../images/svg/social-ut.svg); }
    .icon-whatsapp:before { background-image: url(../images/svg/social-wa.svg); }
    .icon-wechat:before { background-image: url(../images/svg/social-wc.svg); }
    .icon-youtube2:before { background-image: url(../images/svg/social-yt2.svg); }
    .icon-youtube:before { background-image: url(../images/svg/social-yt.svg); }
    .icon-tel:before { background-image: url(../images/svg/social-tel.svg); }
    .icon-rss:before { background-image: url(../images/svg/social-rss.svg); }
}