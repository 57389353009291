.ft-tp { border-top: 1px solid $lite3; }
.ft-logo {
    display: inline-block;
    vertical-align: middle;
    width: 140px;
    img { display: block; }
}
.ft-s3 {
    position: fixed;
    right: 10px; bottom: 60px;
    transition: all .3s;
    z-index: 99;
    &.block {
        .btn-gotop { @include opacity(1); }
    }
}
.design {
    margin: 0 0 10px;
    span {
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
    .design { margin: 0; }
}
@media screen and (min-width: 769px) {
    .ft-logo { padding: 10px 0; }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1400px) {
    .ft-logo { width: auto; }
}