/** 字型 */
$roboto: 'roboto';
$font-en: 'Poppins', "Helvetica Neue", Verdana, Tahoma, Arial;
$font-ch: 'Noto Sans TC', "微軟正黑體修正", "微軟正黑體", "Microsoft JhengHei", "MHei";
$web-font: $font-en, $font-ch, sans-serif;
/** gray */
$black: #000;
$white:#fff;
$red: #E10004;
$orange: #FF7100;
$blue: #0872CD;
$green: #76BD1D;

/** color-skin */
$base: #333;        // 文字基本色
$grey1: #535353;    // 文字淡色
$grey2: #777;       //表單輸入框
$grey3: #999;
$dark1: #2A2D2E;
$lite1: #979AA2;
$lite2: #707F8E;
$lite3: #E3E6EA;

/** Theme */
$body-c: $base;
$body-bgc: $white;      // 背景底色
$mask-bgc: $black;      // 行動版遮罩
$colors: (
    cw: #fff,
    c0: #000,
    c1: #5E259B,
    c2: #4F5562,
    c3: #517092,
    // c4: #2F5F84,
    // c5: #4177A8,
);
$cw: var(--cw);
$c0: var(--c0);
$c1: var(--c1);
$c2: var(--c2);
$c3: var(--c3);
// $c4: var(--c4);
// $c5: var(--c5);
$map-cw: map-get($colors, cw);
$map-c0: map-get($colors, c0);
$map-c1: map-get($colors, c1);
$map-c2: map-get($colors, c2);
$map-c3: map-get($colors, c3);
// $map-c4: map-get($colors, c4);
// $map-c5: map-get($colors, c5);

