.btn {
    @extend %btn-base;
    padding: 14px 25px;
}
.btn-s {
    @extend %btn-s;
    padding: 10px 20px;
}
.btn-m { @extend %btn-m; }
.toggle-btn { cursor: pointer; }
.btn-thumb {
    @include no-text;
    padding: 0;
    width: 32px; height: 32px;
    .thumb {
        display:block; position:absolute;
        width: 20px;
        height: 2px;
        left: 50%;
        margin: 0 0 0 -10px;
        background: #000;
        transition: all .3s;
        transform-origin: center;
    }
    .thumb-1 { top: 7px; }
    .thumb-2 { top: 14px; }
    .thumb-3 { top: 21px; }
    &.on {
        .thumb {
            top:50%;
            margin-top: -1px;
        }
        .thumb-1 { transform: rotate(-45deg); }
        .thumb-2 { @include opacity(0); }
        .thumb-3 { transform: rotate(45deg); }
    }
}
.btn-thumb2 {
    background-color: #F1F3F5;
    &:before {
        content: ''; display: inline-block;
        vertical-align: middle;
        width: 15px; height: 15px;
        margin: -2px 15px 0 0;
        background-image: url(../images/svg/icon-thumb.svg);
        
    }
    @include hover {
        color: $cw;
        background-color: $c1;
        &:before { @include filter-cw; }
    }
}
.btn-switch {
    @include no-text;
    position: relative;
    width: 50px; min-height: 100%;
    padding: 0;
    transition: none;
    &:before {
        @include abs_half;
        content: ''; display: block;
        border: solid $cw;
        border-width: 0 2px 2px 0;
        padding: 4px;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
.btn-gotop {
    @include opacity(0);
    @include no-text;
    position: relative;
    display: block;
    width: 40px; height: 40px;
    padding: 0;
    background-color: rgba(#4F5562, .8);
    &:before {
        @include abs_half;
        content: ''; display: block;
        border: solid $cw;
        border-width: 0 1px 1px 0;
        margin: 2px 0 0;
        padding: 4px;
        transform: translate(-50%, -50%) rotate(-135deg);
    }
}
.btn1 {
    color: $cw;
    background-color: $c1;
    @include hover {
        color: $c1;
        background-color: $cw;
        border-color: $c1;
    }
}
.btn2 {
    color: $cw;
    background-color: $c2;
    @include hover { background-color: $c1; }
}
.btn-stock {
    letter-spacing: 1px;
    &:before {
        @extend %bg-contain;
        content: ''; display: inline-block;
        vertical-align: middle;
        margin: -2px 10px 0 0;
        width: 40px; height: 40px;
        background: url(../images/svg/icon-stock.svg);
    }
}
.btn-search1 {
    position: relative;
    padding: 0 0 0 30px;
    &:before, &:after {
        content: ''; display: block;
        position: absolute;
        left: 0; top: 50%;
        width: 20px; height: 20px;
    }
    &:before {
        background-image: url(../images/svg/icon-search.svg);
        transform: translate(0, -50%);
    }
    &.on {
        color: $c1;
        &:before, &:after {
            width: 20px; height: 2px;
            background-color: $c1;
        }
        &:before { transform: rotate(-45deg); }
        &:after { transform: rotate(45deg); }
    }
}
.btn-search2 {
    @include no-text;
    position: relative;
    padding: 0;
    width: 50px; height: 50px;
    font-family: $roboto;
    &:before {
        @include abs_half;
        @extend %bg-contain;
        content: ''; display: block;
        width: 20px; height: 20px;
        background-image: url(../images/svg/icon-search-b1.svg);
    }
    @include hover {
        background-color: $c1;
        &:before { @include filter-cw; }
    }
}
.btn-rc1, .btn-rcw1, .btn-rcw2, .btn-rcw3, .btn-rcw32, .btn-rcw33 {
    &:after {
        content: ''; display: inline-block;
        width: 15px; height: 15px;
        vertical-align: middle;
        margin: -2px 0 0 10px;
        background-image: url(../images/svg/icon-arr-r1.svg);
    }
}
.btn-rcw1, .btn-rcw2, .btn-rcw3, .btn-rcw32, .btn-rcw33 {
    &:after { @include filter-cw; }
}
.btn-rc1 { padding: 0 5px 5px 0; }
.btn-rcw1 {
    border: 2px solid $c0;
    border-width: 0 0 2px;
    padding: 2px 0 6px;
    &:after { @include filter-c0; }
}
.btn-rcw2 {
    color: $cw;
    padding: 0 5px 5px 0;
    border-bottom: 2px solid $cw;
    @include hover {
        &:after { margin-left: 20px; }
    }
}
.btn-rcw3, .btn-rcw32, .btn-rcw33 {
    color: $cw;
    padding: 15px 25px;
    &:after { margin-left: 20px; }
    @include hover { background-color: $c1; }
}
.btn-rcw3 {
    border-color: $cw;
    @include hover {
        border-color: $c1;
        background-color: $c1;
    }
}
.btn-rcw33 {
    border-color: $c1;
    background-color: $c1;
    @include hover {
        color: $c1;
        background-color: $cw;
        &:after { filter: none; }
    }
}
.btn-bd1, .btn-bd2 {
    padding: 8px 20px;
    border: 1px solid #acacac;
    @include hover {
        color: $cw;
        background-color: $c1;
        border-color: $c1;
    }
}
.btn-bd1 { background-color: #F5ECFF; }
.btn-download1, .btn-download2 {
    &:before {
        content: ''; display: inline-block;
        vertical-align: middle;
        background-image: url(../images/svg/icon-download1.svg);
    }
}
.btn-download1 {
    color: $cw;
    padding: 13px 25px;
    background-color: #4F5562;
    &:before {
        @include filter-cw;
        width: 25px; height: 25px;
        margin: -2px 10px 0 0;
    }
    @include hover { background-color: $c1; }
}
.btn-download2 {
    @include no-text;
    height: 40px;
    min-height: 100%;
    padding: 0;
    &:before {
        @include abs_half;
        width: 30px; height: 30px;
        margin-left: 15px;
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .btn-gotop {
        width: 60px; height: 60px;
        &:before {
            margin-top: 5px;
            padding: 8px;
        }
    }
}
@media screen and (min-width: 1200px) {
    .btn-gotop {
        right: 30px;
        width: 70px; height: 70px;
        &:before {
            margin-top: 7px;
            padding: 10px;
        }
    }
    .btn-switch { display: none; }
    .btn-stock {
        @include font(12);
        padding: 0;
        &:before {
            display: block;
            margin: 0 auto 5px;
        }
    }
}
@media screen and (min-width: 1400px) {}
@media screen and (min-width: 1600px) {}