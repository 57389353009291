/** page-limit */
.page-limit {
    a, span {
        position: relative;
        display: block;
        width: 40px; height: 40px;
        line-height: 42px;
        
    }
    li {
        position: relative;
        background-color: $cw;
        z-index: 0;
        box-shadow:
            1px 0 0 0 #e9e9e9, 
            0 1px 0 0 #e9e9e9, 
            1px 1px 0 0 #e9e9e9,   /* Just to fix the corner */
            1px 0 0 0 #e9e9e9 inset, 
            0 1px 0 0 #e9e9e9 inset;
        *border:1px solid #e9e9e9;
    }
    a {
        text-decoration:none;
        @include hover {
            color: $cw;
            background-color: lighten($map-c1, 10%);
        }
    }
    .on {
        a {
            color: #fff;
            background-color: $c1;
            pointer-events: none;
        }
    }
    .off {
        a {
            opacity: .5;
            pointer-events: none;
        }
    }
    .first, .last, .prev, .next {
        a {
            @include no-text;
            padding: 0;
            &:after {
                @include abs_half;
                @extend %bg-contain;
                content: ''; display: block;
                left: 50%; top: 50%;
                width: 14px; height: 14px;
                margin: 0;
            }
            @include hover {
                &:after { @include filter-cw; }
            }
        }
    }
    .first a:after { background-image: url(../images/svg/arrow-first.svg); }
    .last a:after { background-image: url(../images/svg/arrow-last.svg); }
    .prev a:after {
        background-image: url(../images/svg/arrow-prev.svg);
        background-size: 15px auto;
    }
    .next a:after {
        background-image: url(../images/svg/arrow-next.svg);
        background-size: 15px auto;
    }
}
@media screen and (min-width: 768px) {
    .page-limit {
        a, span {
            width: 50px; height: 50px;
            line-height: 52px;
        }
    }
}
@media screen and (min-width: 1200px) {}