.icon {
    position: relative;
    display: inline-block;
    vertical-align: middle
}
.icon-home {
    @include no-text;
    @extend %bg;
    width: 30px; height: 30px;
    background-image: url(../images/svg/icon-home.svg);
    background-size: 20px auto;
    filter: grayscale(1);
    opacity: .8;
    @include hover {
        opacity: 1;
        filter: none;
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {}