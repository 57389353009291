/** common */
*:before, *:after { transition: all .3s; }
#container, #content {
	position: relative;
    margin: 0 auto;
}
#container {
    @extend %bg;
    min-width: 320px;
}
#content {
    clear:both;
    z-index: 1;
}
#mask-bg {
    opacity: 0;
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 0;
    background: $mask-bgc;
    overflow: hidden;
    z-index: 998;
    transition: opacity .3s;
    &.on {
        opacity: .9;
        height:100%;
    }
}
[class^="bg-"] { position: relative; }
.over-hide { overflow: hidden; }
.obj-cover {
    @include abs_full;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}
.obj-contain {
    @include abs_half;
    object-fit: contain;
    max-height: 100%;
}
.obj-left {
    position: absolute;
    left: 0; top: 50%;
    transform: translate(0, -50%);
    object-fit: contain;
}
.figure, .tp-figure, .figure-bd {
    @extend %bg-contain;
    position: relative;
    transition: all .5s;
    display: block;
    img { display: block; margin: 0 auto; }
}
.figure-bd { border: 1px solid #d5d5d5; }
.bg { @extend %bg; }
.bg-cover { @extend %bg-cover; }
.bg-contain { @extend %bg-contain; }
.bg2 { background-image: url(../images/bg2.jpg); }
.bg-lite3 { background-color: $lite3; }
.bg-clip {
    position: relative;
    z-index: 0;
    &:before, &:after {
        @include abs_full;
        @extend %bg;
        background-position: center top;
        content: ''; display: block;
        z-index: -1;
    }
    &:before {
        background-color: $lite3;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 95%);
    }
    &:after { clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%); }
}
.bg-h1 {
    z-index: 0;
    &:before {
        @extend %bg-linear;
        display: block;
        width: 29.6875%; height: 100%;
        position: absolute;
        right: 0; top: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 45% 100%);
        z-index: -1;
    }
}
.bg-h2 {
    &:after {
        background-image: url(../images/bg-h2.jpg);
        background-color: #05070A;
    }
}
.bg-h4 { background: url(../images/bg-comm.png) center bottom -200px repeat-x $cw; }
.bg-shadow {
    position: relative;
    background: url(../images/bg-comm.png) center bottom -15px repeat-x $cw;
    z-index: 0;
    &:before {
        content: ''; display: block;
        position: absolute;
        left: 0; top: 0;
        background-color: $lite3;
        width: 100%; height: 30%;
        z-index: -1
    }
}
.bg-clip1 { @extend %bg-linear; }
.bg-ind {
    background: url(../images/bg-ind.jpg) center bottom repeat-x;
    background-blend-mode: multiply;
    background-size: contain;
}
.bg-about { background: url(../images/bg-about.png) center bottom repeat-x #100B23; }
.break-word {
    word-wrap: break-word;
    word-break: break-word;
}
.link, .link-c1 { text-decoration: underline; }
.hover-link { @extend %hover-link; }
.rounded, .rounded-s, .rounded-10, .rounded-12, .rounded-20, .rounded-27, .rounded-pill { overflow: hidden; }
.rounded-0 { @include radius(0); }
.rounded-s { @include radius(3px); }
.rounded { @include radius(6px); }
.rounded-10 { @include radius(10px); }
.rounded-12 { @include radius(12px); }
.rounded-15 { @include radius(15px); }
.rounded-20 { @include radius(20px); }
.rounded-27 { @include radius(27px); }
.rounded-pill { @include radius(30px); }
.circle { @include radius(50%); }
.ti { font-style: italic; }
.tl {
    text-align: left;
    img { margin: 0; }
}
.tc { text-align: center; }
.tr {
    text-align: right;
    img { margin: 0 0 0 auto; }
}
.ts, .t-shadow { text-shadow: 0.5px 0 0 currentColor; }
.shadow { box-shadow: 0 0 24px rgba(0, 0, 0, .04); }
.shadow1 { box-shadow: 0 3px 27px rgba(#828282, .16); }
.view-pc, .d-pc { display: none; }
.pos-r { position: relative; }
.z0 { z-index: 0; }
.clamp-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.clamp-2 {
    @extend %clamp;
    -webkit-line-clamp: 2;
}
.clamp-3 {
    @extend %clamp;
    -webkit-line-clamp: 3;
}
.hr1, .hr-ce {
    height: 0;
    border: 1px solid;
    border-width: 0 0 1px;
}
.hr1 { border-color: #aaa; }
.hr-ce { border-color: #cecece; }
.cursor { cursor: pointer; }
.h-100 { min-height: 100%; }
.label {
    display: inline-block;
    line-height: 1.2;
    padding: 8px 20px;
}
.nowrap { white-space: nowrap; }
.sticky { position: sticky; }
.anchor {
    position: relative;
    padding-top: 80px; margin-top: -80px;
    pointer-events: none;
    z-index: -1;
}
.clip-b1 { clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%); }
.clip-l1 { clip-path: polygon(15px 0, 100% 0, 100% 100%, 0 100%); }
.clip-l2 { clip-path: polygon(55px 0, 100% 0, 100% 100%, 0 100%); }
.link-prev1, .link-next1 {
    position: relative;
    &:before, &:after {
        @extend %bg-contain;
        position: absolute;
        top: 50%;
        display: block;
        width: 20px; height: 20px;
        opacity: .2;
        filter: grayscale(1);
    }
    @include hover {
        &:before, &:after {
            opacity: 1;
            filter: none;
        }
    }
}
.link-prev1 {
    padding: 0 0 0 40px;
    &:before {
        content: ''; left: 0;
        transform: translate(0, -50%);
        background-image: url(../images/svg/arrow-prev1.svg);
    }
}
.link-next1 {
    padding: 0 40px 0 0;
    &:after {
        content: ''; right: 0;
        transform: translate(0, -50%);
        background-image: url(../images/svg/arrow-next1.svg);
    }
}
@media screen and (min-width: 576px) {
    .s-tl { text-align: left; }
    .s-tc { text-align: center; }
    .s-tr { text-align: right; }
    .col-sm-l { margin-left: 0; margin-right: auto; }
    .col-sm-r { margin-right: 0; margin-left: auto; }
    .bg-clip {
        &:before { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%); }
        &:after { clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%); }
    }
}
@media screen and (min-width: 768px) {
    .m-tl {
        text-align: left;
        img { margin: 0; }
    }
    .m-tc { text-align: center; }
    .m-tr {
        text-align: right;
        img { margin: 0 0 0 auto; }
    }
}
@media screen and (min-width: 769px) {
    .ml-tl {
        text-align: left;
        img { margin: 0; }
    }
    .ml-tc { text-align: center; }
    .ml-tr {
        text-align: right;
        img { margin: 0 0 0 auto; }
    }
    #container { min-width: 1024px; }
    .view-pc, .d-pc { display: block; }
    .view-mb, .d-mobile { display: none; }
    .ml-sticky {
        position: sticky;
        top: 80px;
    }
    .anchor { padding-top: 120px; margin-top: -120px; }
    .bg-h1:before { content: ''; }
    .bg-clip {
        &:before { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 85%); }
        &:after { clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%); }
    }
    .bg-clip2 {
        background: url(../images/bg-clip2.svg) right top no-repeat;
        background-size: 37% auto;
    }
    .clip-r1 { clip-path: polygon(0 0, 100% 0, 84% 100%, 0% 100%); }
}
@media screen and (min-width: 1200px) {
    .l-tl {
        text-align: left;
        img { margin: 0; }
    }
    .l-tc { text-align: center; }
    .l-tr {
        text-align: right;
        img { margin: 0 0 0 auto; }
    }
}
@media screen and (min-width: 1400px) {
    .x-tl {
        text-align: left;
        img { margin: 0; }
    }
    .x-tc { text-align: center; }
    .x-tr {
        text-align: right;
        img { margin: 0 0 0 auto; }
    }
    .bg-clip {
        &:before { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%); }
        &:after { clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); }
    }
    .bg-clip2 {
        background-size: 700px auto;
    }
}