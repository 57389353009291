/** model*/
.tp-gdpr {
    position: fixed;
    left: 0; bottom: 0;
    width: 100%;
    p:last-child { margin: 0; }
}
.tp-form1, .tp-form2 {
    position: relative;
    .form-ctrl {
        @include radius(6px);
        max-width: none;
    }
    .btn {
        position: absolute;
        right: 0; top: 0;
        height: 100%;
        @include radius(0 6px 6px 0);
    }
}
.tp-form2 {
    ::-webkit-input-placeholder { color:$grey3; }
    :-moz-placeholder { color:$grey3; }
    ::-moz-placeholder { color:$grey3; }
    :-ms-input-placeholder { color:$grey3; }
}
.tp2 {
    z-index: 0;
    &:after {
        @include abs_full;
        @extend %bg-contain;
        content: ''; display: block;
        background-image: url(../images/svg/bd-linear1.svg);
        opacity: 0;
        z-index: -1;
    }
    @include hover {
        .title, &:after { opacity: 1; }
    }
}
.tp3 {
    .figure {
        z-index: 0;
        &:before, &:after {
            @extend %bg-contain;
            content: ''; display: block;
            opacity: 0;
            z-index: 1;
        }
        &:before {
            @include abs_full;
            @extend %bg-linear;
            
        }
        &:after {
            @include abs_half;
            width: 17%; height: 100%;
            max-width: 55px;
            background-image: url(../images/svg/icon-plus.svg);
        }
    }
    @include hover {
        color: $c1;
        .figure {
            &:before { opacity: .74; }
            &:after { opacity: 1; }
        }
    }
}
.tp4 {
    .tp-s1 {
        max-width: 92px;
        margin: 0 auto 20px;
    }
}
.tp-clip-rb {
    position: relative;
    border: 2px solid $lite3;
    background-color: $lite3;
    padding: 0 0 15%;
    clip-path: polygon(0% 0%, 100% 0, 100% 88%, 75% 100%, 0% 100%);
    z-index: 0;
    ul { padding: 10px 40px; }
    .title {
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
        margin-left: -2px;
        &:before {
            content: ''; display: block;
            position: absolute;
            left: 0; top: -20px;
            width: 4px; height: 100%;
            background-color: $c1;
        }
        &:after {
            content: ''; display: block;
            margin-top: 40px;
            height: 1px;
            background-color: #e2e2e2;
        }
    }
    &:before {
        @include abs_full;
        content: ''; display: block;
        background-color: $cw;
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0, 100% 88%, 75% 100%, 0% 100%);
    }
}
.tp-download {
    .col:last-child { transition: all .3s; }
    @include hover {
        .title { color: $c1; }
        .col:last-child { background-color: $c1; }
        .btn:before { @include filter-cw; }
    }
}
.tp-refresh {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &:after {
        @extend %bg-contain;
        content: ''; display: block;
        position: absolute;
        right: 5px; bottom: 2px;
        width: 20px; height: 20px;
        background-image: url(../images/svg/icon-refresh.svg);
    }
}
.sec-shadow {
    position: relative;
    z-index: 0;
    &:before {
        content: ''; display: block;
        position: absolute;
        left: 0; top: 0;
        background-color: $lite3;
        width: 100%; height: 26%;
        z-index: -1
    }
}
@media screen and (min-width: 375px) {}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 576px) {
    .tp-form2 {
        .form-ctrl {
            @include radius(6px 0 0 6px);
            flex: 1;
            max-width: none;
            padding-left: 80px;
            background: url(../images/svg/icon-search-b1.svg) 25px center no-repeat $cw;
            background-size: 20px auto;
            @include hover(true) { border-color: $c1; }
        }
        .btn {
            @include radius(0 6px 6px 0);
            @include font(16, 18);
            text-indent: 0;
            position: static;
            padding: 16px 25px;
            color: $cw;
            background-color: $c1;
            width: auto;
            overflow: hidden;
            &:before { content: none; }
        }
    }
    .tp4 {
        display: flex;
        .tp-s2 {
            flex: 1;
            padding: 0 0 0 40px;
        }
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .tp-gdpr {
        padding: 10px 20px;
        .sec-inner {
            display: flex;
            align-items: center;
        }
        .tp-s1 {
            padding: 0;
            flex: 1;
        }
    }
    .tp1, .tp1-2 {
        color: $cw;
        .text {
            position: absolute;
            left: 0; bottom: 0;
        }
        .btn {
            border-color: $cw;
            &:after { @include filter-cw; }
        }
        @include hover {
            .text { animation: fadeIn .3s; }
            .d-ml-none { display: block; }
        }
    }
    .tp1 {
        height: 100%;
        .figure {
            height: 100%;
            padding: 0;
        }
    }
    .tp2 {
        height: 0;
        padding: 0 0 100%;
        .tp-inner {
            @include abs_half;
            width: 100%;
        }
    }
}
@media screen and (min-width: 1200px) {
    .tp4 {
        .tp-s2 { padding: 0 50px; }
    }
    .tp-clip-rb {
        clip-path: polygon(0% 0%, 100% 0, 100% 85%, 80% 100%, 0% 100%);
        &:before {
            clip-path: polygon(0% 0%, 100% 0, 100% 85%, 80% 100%, 0% 100%);
        }
    }
}
@media screen and (min-width: 1400px) {}
@media screen and (min-width: 1600px) {}