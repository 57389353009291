/** banner */
.banner {
    position:relative;
    overflow: hidden;
}
.banner2 {
    height: 240px;
    .figure {
        @include abs_full;
        overflow: hidden;
    }
    .text {
        position: absolute;
        left: 0; top: 50%;
        width: 100%;
        transform: translate(0, -50%);
    }
    &.init {
        img { animation: scaleIn 3s; }
    }
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 641px) {}
@media screen and (min-width: 768px) {
    .banner2 { height: 280px; }
}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {
    .banner2 { height: 312px; }
}
@media screen and (min-width: 1400px) {}