*, *:before, *:after { box-sizing: inherit; }
*:before, *:after { transition: all .3s; }
:root {
    @each $name, $color in $colors { --#{$name}: #{$color}; }
}
html {
	box-sizing: border-box;
    height:100%;
}
body {
    @include font(16, 18);
    position:relative;
    color: $body-c;
    background: $body-bgc;
    font-family: $web-font;
    font-weight: 400;
    &.lock {
        overflow:hidden;
        -webkit-backface-visibility: hidden;
    }
}
address { font-style: normal; }
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 500;
    &:first-child { margin-top: 0; }
    & + p { margin-top: 0; }
}
p { 
    &:first-child { margin-top: 0; }   
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    & + p { padding-top: 10px; }
}
a, button {
    outline: 0 none;
    transition: all .3s;
    &:focus, &:focus-visible { outline: 0 none; }
}
a {
    color: inherit;
    text-decoration: none;
    &[href^="mailto"] { word-break: break-all; }
}
nav {
	@extend %list-wrap;
    a {
        @extend %hover-c1;
        display: block;
    }
}
@media screen and (min-width: 769px) {}