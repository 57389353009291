
/* 修正粗體 */
@font-face {
    font-family: '微軟正黑體修正';
    unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
    font-style: normal;
    font-weight: bold;
    src: local(Yu Gothic), local(MS Gothic);
}
/* 一般粗細的時候改回微軟正黑 */
@font-face {
    font-family: '微軟正黑體修正';
    unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
    font-style: normal;
    font-weight: normal;
    src: local(微軟正黑體);
}