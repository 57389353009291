// 色系 - 對應variables檔案的預設顏色
@each $name, $color in $colors {
    .#{$name} { @extend %#{$name}; } // .cw, .c0 ~ cx
    .bg#{$name} { @extend %bg#{$name}; } // .bgcw, .bgc0 ~ bgcx
    .hover-#{$name} { @extend %hover-#{$name}; } // .hover-cw, .hover-c0 ~ hover-cx
    .hover-bg#{$name}{ @extend %hover-bg#{$name}; } // .hover-bgcw, .hover-bgc0 ~ hover-bgcx
    .hover-bd#{$name}{ @extend %hover-bd#{$name}; } // .hover-bdcw, .hover-bdc0 ~ hover-bdcx
    @if $name != 'c0' {
        .hover-#{$name}bgc0 { // .hover-cxbgc0, 不含hover-c0bgc0
            @extend %hover-#{$name};
            @extend %hover-bgc0;
        }
    }
    @if $name != 'cw' {
        .hover-cwbg#{$name} { // .hover-cwbgcx, 不含hover-cwbgcw
            @extend %hover-cw;
            @extend %hover-bg#{$name};
            @include hover {
                &:before, &:after { @include filter-cw; }
            }
        }
        .hover-#{$name}bgcw { // .hover-cwbgcx, 不含hover-cwbgcw
            @extend %hover-#{$name};
            @extend %hover-bgcw;
            @include hover {
                &:before, &:after { filter: none; }
            }
        }
    }
}
.red { @extend %red; }
.orange { @extend %orange; }
.blue { @extend %blue; }
.green { @extend %green; }
.black { @extend %c0; }
.white { @extend %cw; }
.o22 { opacity: .22; }
.o66 { opacity: .66; }
.c5f { color: #5f5f5f; }
.c3e { color: #3e3e3e; }
.c63 { color: #636363; }
.c7b { color: #7b7b7b; }
.c87 { color: #878787; }
.cbe { color: #bebebe; }
.c-lite1 { color: $lite1; }
.c-lite2 { color: $lite2; }
.bgc1-lite1 { background-color: #F5ECFF; }
.bgc-lite3 { background-color: $lite3; }
.bgcw-o7 { background-color: rgba($map-cw, .7); }
.bgcw-linear { background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 75%); }
.bg-linear1 { @extend %bg-linear; }
.bg-linear2 { @extend %bg-linear2; }
.bg-linear3 { @extend %bg-linear3; }
.bdl3-c1, .bdl4-c1 {
    position: relative;
    &:before {
        content: ''; display: block;
        position: absolute;
        left: 0; top: 1px;
        width: 3px; height: 100%;
        background-color: $c1;
    }
}
.bdl3-c1:before { max-height: 20px; }
.bdl4-c1:before { max-height: 160px;  }
.bd1-cdf { border: 1px solid #dfdfdf; }
.bdb1-cd5 { border-bottom: 1px solid #d5d5d5; }
.bdt1-cd5 { border-top: 1px solid #d5d5d5; }
.bd-linear1, .bd-linear2 {
    &:after {
        @extend %bg-linear;
        content: ''; display: block;
        width: 5px; height: 80px;
        margin: 15px auto;
    }
}
.bd-linear2:after {
    margin: 20px auto;
    height: 50px;
}
.bg-linear4:after { @extend %bg-linear; }
@media screen and (min-width: 769px) {
    .bd-linear1:after { width: 7px; height: 105px; }
    .bd-linear2:after { height: 70px; }
}
@media screen and (min-width: 1200px) {}