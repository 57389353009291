.fw1 { font-weight: 100; } //  thin
.fw2 { font-weight: 200; } //  extraLight
.fw3 { font-weight: 300; } //  light
.fw4 { font-weight: 400; } //  regular
.fw5 { font-weight: 500; } //  medium
.fw6 { font-weight: 600; } //  semibold
.fw7 { font-weight: 700; } //  bold
.fw8 { font-weight: 800; } //  extrabold
.fw9 { font-weight: 900; } //  black
.fs0 { font-size: 0; }
.fs12 { @include font(12); }
.fs14 { @include font(14); }
.fs15, h6 { @include font(14, 15); }
.fs16, h5 { @include font(14, 16); }
.fs16- { @include font(16); }
.fs17 { @include font(16, 17); }
.fs18 { @include font(16, 18); }
.fs18- { @include font(18); }
.fs19 { @include font(18, 19); }
.fs20 { @include font(18, 20); }
.fs21 { @include font(18, 21); }
.fs22 { @include font(20, 22); }
.fs23 { @include font(20, 23); }
.fs24, h4 { @include font(22, 24); }
.fs26, h3 { @include font(24, 26); }
.fs28, h2 { @include font(26, 28); }
.fs30 { @include font(28, 30); }
.fs32, h1 { @include font(30, 32); }
.fs38 { @include font(34, 38); }
.fs40 { @include font(34, 36, 40); }
.fs42 { @include font(36, 40, 42); }
.fs46 { @include font(38, 42, 46); }
.lh1 { line-height: 1; }
.lh1-1 { line-height: 1.1; }
.lh1-2 { line-height: 1.2; }
.lh1-3 { line-height: 1.3; }
.lh1-4 { line-height: 1.4; }
.lh1-5 { line-height: 1.5; }
.lh1-6 { line-height: 1.6; }
.lh1-7 { line-height: 1.7; }
.lh1-8 { line-height: 1.8; }
.lh1-9 { line-height: 1.9; }
.lh2 { line-height: 2; }
.lh2-1 { line-height: 2.1; }
.lh2-2 { line-height: 2.2; }
.lh-nor { line-height: normal; }
.ttu { text-transform: uppercase; }
.ttl { text-transform: lowercase; }
.ttc { text-transform: capitalize; }
.ls1 { letter-spacing: 1px; }
.ls2 { letter-spacing: 2px; }
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1400px) {}