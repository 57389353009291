/** editor */
pre, code {
    font-weight: normal;
    font-family: 'Courier New', 'Times New Roman';
}
.editor, .blockquote {
    h1, h2, h3, h4, h5, h6 {
        & + p, & + ul, & + dl { margin-top: 0; }
    }
    a:not(.btn) { 
        //color: #efaa1a;
        text-decoration: underline;
        @include hover { color: $c1; }
    }
    p { padding-bottom: 20px; }
}
.article {
    h2 { color: #404040; }
    h3 { color: $c1; }
    //h4 { color: $c4; }
}
.blockquote, .blockquote02 {
    margin: 20px auto;
    padding: 20px;
    p {
        margin: 0;
        & + p { margin-top: inherit; }
    }
    a {
        color: $c1;
        text-decoration: underline;
        @include hover { color: $c2; }
    }
}
.blockquote {
    font-style: normal;
    font-weight: bold;
    border: solid #f8f8f8;
    border-width: 3px 0;
}
.blockquote02 {
    background: #F9F9F9;
    border-left: 2px solid $c1;
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
    .blockquote { padding: 25px 40px; }
    .blockquote02 { padding: 25px 40px; }
}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {
    .blockquote { padding: 25px 60px; }
    .blockquote2 { padding: 30px 60px; }
}