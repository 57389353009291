@mixin font-size($num: 14) {
    $base:0.625;
    font-size: $num + px;
    font-size: ($num * $base / 10) + rem;
}
@mixin font-m($font-size01, $font-size02:$font-size01, $font-size03:$font-size02) {
    @include font-size($font-size01);
    @if ($font-size01 != $font-size02) {
        @media screen and (min-width: 768px){
           @include font-size($font-size02);
        }
    }
    @if ($font-size02 != $font-size03) {
        @media screen and (min-width: 1200px){
           @include font-size($font-size03);
        }
    }
}
@mixin font-l($font-size01, $font-size02:$font-size01, $font-size03:$font-size02) {
    @include font-size($font-size01);
    @if ($font-size01 != $font-size02) {
        @media screen and (min-width: 1200px){
            @include font-size($font-size03);
         }
    }
}
@mixin font($font-size01, $font-size02:$font-size01, $font-size03:$font-size02) {
    @include font-size($font-size01);
    @if ($font-size01 != $font-size02) {
        @media screen and (min-width: 769px){
           @include font-size($font-size02);
        }
    }
    @if ($font-size02 != $font-size03) {
        @media screen and (min-width: 1200px){
           @include font-size($font-size03);
        }
    }
}
@mixin ellipsis ($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
@mixin flex($fw:null, $fj:null, $fa:null) {
    display:flex;
    flex-wrap: $fw;
    justify-content: $fj;
    align-items: $fa;
}
@mixin max-w($max-w) {
    max-width: 100 * $max-w + px;
    margin: 0 auto;
}
@mixin min-w($min-w) {
    min-width: 100 * $min-w + px;
}
@mixin btn($btn-bg, $btn-color, $btn-hover-bg, $btn-hover-color) {
    background: $btn-bg;
    color: $btn-color;
    @media screen and (min-width: 769px){
       &:hover{
           background: $btn-hover-bg;
           color: $btn-hover-color;
           @content;
        }
    }
}
@mixin bg($bgsz){
    background-position: center;
    background-repeat: no-repeat;
    background-size: $bgsz;
}
//left  abs($abs-left:0, $abs-x:0)
//right abs($abs-left:unset, $abs-right:0, $abs-x:0)
@mixin no-text {
    font-size: 0;
    text-align: left;
    text-indent: -999px;
    overflow: hidden;
    @content;
}
@mixin abs_full{
    position: absolute;
    left:0; top:0;
    width:100%; height: 100%;
    @content;
}
@mixin abs_half {
    transform: translate(-50%, -50%);
    position: absolute;
    left:50%; top:50%;
    @content;
}
//絕對居中
@mixin abs(){
    position: absolute;
    top:50%; left:50%;
    transform: translate(-50%,-50%);
    z-index: 1;
}
//水平居中
@mixin abs-x($abs-t:null, $abs-b:null){
    position: absolute;
    top:$abs-t;
    bottom:$abs-b;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
//垂直居中
@mixin abs-y($abs-l:null, $abs-r:null){
    position: absolute;
    left:$abs-l;
    right:$abs-r;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
@mixin fake-b($fake-b-w:null, $fake-b-h:null) {
    content:'';
    display: block;
    width: $fake-b-w;
    height: $fake-b-h;
}
@mixin fake-ib($fake-ib-w:null, $fake-ib-h:null) {
    content:'';
    display: inline-block;
    vertical-align: middle;
    width: $fake-ib-w;
    height: $fake-ib-h;
    line-height: 1;
    @include bg(contain);
}
@mixin list{
    margin:0; padding:0;
    list-style: none outside none;
    @content;
}
@mixin hover-c($color){
    @media screen and (min-width: 769px){
        &:hover { color: $color; }
    }
}
@mixin hover-bgc($color){
    @media screen and (min-width: 769px){
        &:hover { background-color: $color; }
    }
}
@mixin hover-bdc($color){
    @media screen and (min-width: 769px){
        &:hover { border-color: $color; }
    }
}
@mixin hover($focus: false) {
    @if ($focus) {
        &:focus { @content; }
    }
    @media screen and (min-width: 769px){
        &:hover { @content; }
    }
}
@mixin filter-cw {
    filter: saturate(0) brightness(10);
}
@mixin filter-c0 {
    filter: saturate(0) brightness(0);
}
@mixin bg-rgba($color, $opacity: 0.3) {
    background-color: rgba(var(#{$color}-r), color(#{$color}-g), var(#{$opacity}-b), $opacity);
}
@mixin opacity($opacity: 0.5) {
    filter: alpha(opacity=$opacity*100);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity*100});
    opacity: $opacity;
}

@mixin radius($radius:5px){ border-radius:$radius; }
