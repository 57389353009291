/** list */
.list, .ul-list {
    @extend %ul-list;
    li { padding: 0; }
}
.dl-list {
    @extend %dl-list;
    dt, dd { margin: 0 auto 5px; padding: 0; }
}
.list-lang {
    margin: 0 -15px;
    li { padding: 5px 15px; }
    a {
        display: block;
        @include hover { text-decoration: underline; }
    }
    .on { color: $c1; }
}
// .list-dot1 {
//     @extend %list;
//     li {
//         position:relative;
//         margin: 0 0 8px;
//         padding: 0 0 0 20px;
//         &:before {
//             content:''; display: block;
//             position: absolute;
//             left: 5px; top: 14px;
//             width: 6px; height: 6px;
//             background-color: $c1;
//             border-radius: 50%;
//         }
//     }
//     span { display: inline-block; }
// }
.list-form1 {
    width: 240px;
    .scroll-sec { padding: 5px 0; }
    ul {
        margin: 0; padding: 0; list-style: none outside none;
        padding: 5px 20px;
        //height: 360px;
        // scrollbar-color: #929292 #f1f1f1;
        // scrollbar-width: thin;
        // overflow-y: scroll;
        // &::-webkit-scrollbar {
        //     width: 6px; height: 6px;
        // }
        // &::-webkit-scrollbar-track {
        //     background-color: #f1f1f1;
        //     border-radius: 6px;
        // }
        // &::-webkit-scrollbar-thumb { border-radius: 6px; }
    }
    li {
        margin: 0;
        padding: 5px 0;
    }
    .label {
        display: block;
        padding: 15px 20px;
    }
}
.list-cate1 {
    @include font(16, 18);
    @extend %list;
    padding: 10px;
    margin-top: 1px;
    background-color: $cw;
    a {
        display: block;
        padding: 8px 20px;
    }
    .on a { pointer-events: none; }
}

.li-dot-c1 {
    margin: 0; padding: 0;
    list-style: none;
    line-height: 1.6;
    li {
        position:relative;
        margin: 0 0 20px;
        padding: 0 0 0 30px;
        &:before {
            content:''; display: block;
            position: absolute;
            left: 5px; top: 12px;
            width: 6px; height: 6px;
            background-color: $c1;
            border-radius: 50%;
        }
    }
}
.list-corp {
    &, dd { margin: 0; padding: 0; }
    //dt { margin: 0 0 10px; }
    dt { margin: 0; }
}
@media screen and (min-width: 375px) {}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .list-cate1 {
        @include radius(10px);
        display: inline-flex;
        justify-content: center;
        margin: 0 -10px;
        li { padding: 5px 10px; }
        a {
            @include radius(10px);
            padding: 10px 20px;
            min-width: 140px;
            @include hover {
                color: $cw;
                background-color: $c1;
            }
        }
        .on a {
            color: $cw;
            background-color: $c1;
        }
    }
}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {
    .list-lang {
        display: none;
        position: absolute;
        top: 100%; right: 0;
        padding: 0;
        width: 150px;
        text-align: center;
        background-color: #fff;
        box-shadow: 1px 0 10px rgba(0, 0, 0, .14);
        li + li { border-top: 1px solid #e2e2e2; }
        a {
            padding: 15px 20px;
            text-decoration: none;
        }
    }
}
@media screen and (min-width: 1400px) {}