/** group */
.flex-tc, .fjcc { justify-content: center; }
.flex-tr, .fjcr { justify-content: right; }
.fjce { justify-content: flex-end; }
.flex-between, .fjcsb { justify-content: space-between; }
.flex-around,.fjcsa { justify-content: space-around; }
.flex-t, .fais { align-items: stretch; }
.flex-c, .faic { align-items: center; }
.flex-b, .faie { align-items: flex-end; }
.flex-col { flex-direction: column; }
.flex-item { width: 100%; }
.flex-1 { flex: 1 auto; }
.as-c { align-self: center; }
.row-1 {
    margin: 0 -10px;
    > .col {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.row-15 {
    margin: 0 -15px;
    > .col {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.row-2, .row-22, .row-25, .row-30, .row-36, .row-44, .row-50 {
    margin: 0 -20px;
    > .col {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.row3-2 {
    margin: 0 -10px;
    > .col { padding: 20px 10px; }
    > .col3 { padding-top: 40px; }
}
.reverse { flex-direction: row-reverse; }

.row-seccode {
    img, .link { cursor: pointer; }
}

.row-cate {
    display: inline-flex;
    padding: 2px 8px;
    .on .btn-s { color: $cw; background-color: $c1; }
}

@media screen and (min-width: 375px) {}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 576px) {
    .sm-reverse { flex-direction: row-reverse; }
}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .m-reverse { flex-direction: row-reverse; }
}
@media screen and (min-width: 769px) {
    .ml-reverse { flex-direction: row-reverse; }
    .row-h4 {
        .text { max-width: 590px; }
    }
    .row-clip1 {
        .row-inner {
            max-width: 1600px;
            z-index: 0;
            &:before {
                content: ''; display: block;
                position: absolute;
                right: 0; top: 0;
                width: 75%; height: 100%;
                background-color: $cw;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
                z-index: -1;
            }
        }
        .i1, .i3 {
            width: 45%;
            max-width: 620px;
            padding-right: 20px;
            float: left;
        }
        .i2 {
            width: 55%;
            float: right;
            background:none;
        }
    }
    .row-22 { 
        margin: 0 -22px;
        > .col {
            padding-left: 22px;
            padding-right: 22px;
        }
    }
    .row-25 { 
        margin: 0 -25px;
        > .col {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    .row-44 {
        margin: 0 -30px;
        > .col {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .row-50 {
        margin: 0 -30px;
        > .col {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
@media screen and (min-width: 1200px) {
    .l-reverse { flex-direction: row-reverse; }
    .row-30 {
        margin: 0 -30px;
        > .col {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .row-36 {
        margin: 0 -36px;
        > .col {
            padding-left: 36px;
            padding-right: 36px;
        }
    }
    .row-44 {
        margin: 0 -44px;
        > .col {
            padding-left: 44px;
            padding-right: 44px;
        }
    }
    .row-50 {
        margin: 0 -50px;
        > .col {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}
@media screen and (min-width: 1400px) {}
@media screen and (min-width: 1600px) {}