/** table */
.tb-wrap, .table-wrap {
    @extend %table-wrap;
    a:not(.btn) {
        @extend %hover-c1;
    }
}
.tb-js {
    table{border-top:1px solid #ddd;}
    thead, th {
        display:none;
        tr, th{display:none;}
    }
    tr{
        display:block;
        position:relative;
        border-bottom:1px solid #ddd;
        padding:5px 0;
    }
    td{
        display:block;
        text-align:left;
        padding:5px 10px;
        &:before {
            display:block;
            content:attr(data-txt);
            @include font-size(12);
            font-weight: bold;
            color: $c1;
            padding:2px 10px 0 0;
        }
    }
}
.tb-corp {
    margin-top: 10px;
    th, td {
        padding: 4px 0;
        vertical-align: top;
    }
    th {
        color: $c2;
        padding-right: 20px;
        font-weight: 500;
    }
    td { color: $lite1; }
}
.tb1, .tb2 {
    overflow: auto;
    table {
        width: 100%;
        //table-layout: fixed;
    }
    th, td {
        border: 1px solid #d2d2d2;
        padding: 15px;
    }
}
.tb1 {
    th {
        @extend %bg-linear2;
        color: $cw;
        border-color: $cw;
        width: auto;
        white-space: nowrap;
        min-width: 150px;
    }
    tr:nth-child(even) {
        td { background-color: #F6F7F8; }
    }
}
.tb2 {
    th {
        background-color: #F1F3F5;
        border-top: 4px solid $c1;
        border-bottom: 1px solid #F1F3F5;
        white-space: nowrap;
        height: 70px;
        width: 220px;
    }
}
.scroll-sec .tb-wrap { overflow: visible; }
@media screen and (min-width: 480px) {}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .tb-wrap {
        table { min-width: 100%; }
    }
}
@media screen and (min-width: 769px) {
    .tb-corp {
        margin-top: 14px;
        th, td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}
@media screen and (min-width: 1200px) {
    .tb1 {
        td {
            height: 82px;
            padding: 10px;
        }
        .w02-4 { max-width: 400px; }
    }
}
@media screen and (min-width: 1400px) {}