::-webkit-input-placeholder { /* WebKit browsers */
    @include font(16, 18);
    color:$grey2;
    opacity:  1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    @include font(16, 18);
    color:$grey2;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    @include font(16, 18);
    color:$grey2;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    @include font(16, 18);
    color:$grey2;
    opacity:  1;
}
input, select, textarea {
    @include font(18, 19);
    outline:0 none;
    padding: 8px 10px;
    appearance: none;
    @include hover(true) {
        border-color: $c1;
        background-color: $cw;
        //box-shadow: 0 0 2px $c1;
    }
}
[type="checkbox"], [type="radio"]{
    width:auto; height:auto;
    padding:0;
    vertical-align:middle;
    cursor:pointer; 
}
[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button { appearance: none; }
}
select { cursor:pointer; vertical-align:middle; }
textarea { height: 120px; }
label { cursor:pointer; }
.form-label, .form-plaintext {
    display: inline-block;
    vertical-align: middle;
}
.form-label {
    i { margin: 0 0 0 5px; }
}
.form-ctrl, .form-ctrl-select {
    @extend %form-ctrl;
    color: #4d4848;
    font-weight: 300;
    &.w-auto {
        min-height: 0;
        min-width: 0;
    }
}
.form-ctrl-select {
    @extend %bg;
    background-image: url(../images/svg/bg-select.svg);
    background-position: right 10px center;
    background-size: 15px auto;
}
.fc-s { padding: 7px 10px 5px; }

.hd-form {
    position: absolute;
    right: 0; top: 100%;
    padding: 10px 20px;
    width: 100%;
    background: $cw;
    box-shadow: 0 0 5px rgba($map-c0, .2);
    z-index: 1;
    &.on { display: block; }
}
.form-label {
    position: relative;
    display: inline-block;
}
.form-chk-input {
    @include abs-full;
    color: #7b7b7b;
    opacity: 0;
    &:checked + .form-chk-text {
        color: #545454;
        &:before {
            border-color: $c1;
            background-color: $c1;
            background-image: url(../images/svg/icon-chk-w.svg);
        }
    }
}
.form-chk-text {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    &:before {
        @extend %bg-contain;
        content: ''; display: block;
        position: absolute;
        left: 0; top: 50%;
        transform: translate(0, -50%);
        border: 1px solid #AFAFAF;
        width: 20px; height: 20px;
        background-size: 90% auto;
    }
}
.form1 {
    .form-ctrl, .form-ctrl-select {
        border-color: transparent;
        background-color: #f6f6f6;
        @include hover {
            border-color: $c1;
            background-color: $cw;
        }
    }
    .label { padding: 0; }
}
.form2 {
    padding-bottom: 30px;
    .row { flex-wrap: nowrap; }
}
@media screen and (min-width: 768px) {
    textarea { height: 150px; }
}
@media screen and (min-width: 769px) {
    .hd-form { width: 400px; }
    .form2 {
        position: relative;
        .abs-btn {
            position: absolute;
            right: 0; bottom: 100%;
            margin: 0 0 80px;
        }
    }
}
@media screen and (min-width: 1200px) {}