/** padding spacing*/
$axis-x: 20px;
$axis-y: 20px;
$percent: (
    p16-9: 9 / 16 * 100%,
    p27-28: 28 / 27 * 100%,
    p5-8: 8 / 5 * 100%,
    p10-7: 7 / 10 * 100%,
    p9-10: 10 / 9 * 100%,
    p9-16: 16 / 9 * 100%,
    p9-14: 14 / 9 * 100%,
    p9-13: 13 / 9 * 100%,
    p8-5: 5 / 8 * 100%,
    p9-8: 8 / 9 * 100%,
    p9-7: 7 / 9 * 100%,
    p9-5: 5 / 9 * 100%,
    p7-5: 5 / 7 * 100%,
    p5-4: 4 / 5 * 100%,
    p5-3: 3 / 5 * 100%,
    p5-2: 2 / 5 * 100%,
    p4-3: 3 / 4 * 100%,
    p3-4: 4 / 3 * 100%,
    p2-3: 3 / 2 * 100%,
    p3-2: 2 / 3 * 100%,
    p3-1: 1 / 3 * 100%,
    p2-1: 1 / 2 * 100%,
    p1-1: 1 / 1 * 100%,
);
@each $name, $value in $percent {
    .#{$name} {
        height: 0;
        padding: 0 0 $value;
    }
}
$ext_maxw: (
    w01-5: 150,
    w02-4: 240,
    w02-6: 260,
    w03-2: 320,
    w03-4: 340,
    w03-5: 350,
    w03-6: 360,
    w04-2: 420,
    w04-4: 440,
    w04-6: 460,
    w06-3: 630,
    w06-6: 660,
    w07-4: 740,
    w09-2: 920,
    w13-2: 1320,
    w13-6: 1360,
    w15-4: 1540,
);
@each $name, $width in $ext_maxw {
    .#{$name} {
        @extend %m0-auto;
        max-width: $width + px;
    }
}
@for $i from 1 through 18 {
    .w#{if($i < 10, '0' + $i, $i)} {
        margin-left: auto;
        margin-right: auto;
        max-width: (100 * $i) + px;
    }
}
.w-auto {
    display: inline-block;
    width: auto;
}
.mw01-3 { min-width: 130px; }
.mw01-4 { min-width: 140px; }
.mw01-8 { min-width: 180px; }
.mw02-4 { min-width: 240px; }
.mb-w50p { max-width: 50%; margin: 0 auto; }
.pl1 { padding-left: $axis-x; }
.pr1 { padding-right: $axis-x; }
.pt-half { padding-top: $axis-y / 2; }
.pb-half { padding-bottom: $axis-y / 2; }
.py-half {
    padding-top: $axis-y / 2;
    padding-bottom: $axis-y / 2;
}
.px-half {
    padding-left: $axis-x / 2;
    padding-right: $axis-x / 2;
}
.pr-half { padding-right: $axis-x / 2; }
.pl-half { padding-left: $axis-x / 2; }
.p-half { padding: $axis-y / 2 $axis-x / 2; }
.pl1-half { padding-left: $axis-x * 1.5; }
.pr1-half { padding-right: $axis-x * 1.5; }
.px1-half {
    padding-left: $axis-x * 1.5;
    padding-right: $axis-x * 1.5;
}
.py1-half {
    padding-top: $axis-y * 1.5;
    padding-bottom: $axis-y * 1.5;
}
.pt1-half { padding-top: $axis-y * 1.5; }
.pb1-half { padding-bottom: $axis-y * 1.5; }
.p1-half { padding: $axis-y * 1.5 $axis-x * 1.5; }
.m0 { margin:0; }
.mr0 { margin-right: 0; }
.mx0 {
    margin-left:0;
    margin-right: 0;
}
.mt-auto { margin-top: auto; }
.mt0 { margin-top: 0; }
.mb0 { margin-bottom: 0; }
.my-half {
    margin-top: $axis-y / 2;
    margin-bottom: $axis-y / 2;
}
.mt-half { margin-top: $axis-y / 2; }
.mb-half { margin-bottom: $axis-y / 2; }
.ml-half { margin-left: $axis-x / 2; }
.mr-half { margin-right: $axis-x / 2; }
.mt1-half { margin-top: $axis-y * 1.5; }
.mb1-half { margin-bottom: $axis-y * 1.5; }
.my1-half {
    margin-top: $axis-y * 1.5;
    margin-bottom: $axis-y * 1.5;
}
.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
@for $i from 1 through 8 {
    .my#{$i} {
        margin-top: $axis-y * $i;
        margin-bottom: $axis-y * $i;
    }
    .mt#{$i} { margin-top: $axis-y * $i; }
    .mb#{$i} { margin-bottom: $axis-y * $i; }
    .sx#{$i} {
        padding-left: $axis-x * $i;
        padding-right: $axis-x * $i;
    }
    .sl#{$i}, .pl#{$i} {padding-left: $axis-x * $i;}
    .sr#{$i}, .pr#{$i} {padding-right: $axis-x * $i;}
}
@for $i from 0 through 12 {
    @if $i < 5 {
        .p#{$i} { padding: $axis-y * $i $axis-x * $i; }
        .px#{$i}, .sx#{$i} {
            padding-left: $axis-x * $i;
            padding-right: $axis-x * $i;
        }
        .py#{$i}, .sy#{$i} {
            padding-top: $axis-y * $i;
            padding-bottom: $axis-y * $i;
        }
        .pt#{$i}, .st#{$i} { padding-top: $axis-y * $i; }
        .pb#{$i}, .sb#{$i} { padding-bottom: $axis-y * $i; }
    }
    @else {
        .py#{$i}, .sy#{$i} {
            padding-top: $axis-y * $i;
            padding-bottom: $axis-y * $i;
        }
        .pt#{$i}, .st#{$i} { padding-top: $axis-y * $i; }
        .pb#{$i}, .sb#{$i} { padding-bottom: $axis-y * $i; }
    }
}
.col-20 {
    flex: 0 0 20%;
    max-width: 20%;
}
.col-auto {
    flex: 0 0 auto;
    max-width: auto;
}
.h02-1 { height: 210px; }
@media screen and (min-width: 576px) {
    .s-m0 { margin:0; }
    .s-mr0 { margin-right: 0; }
    .s-ml0 { margin-left: 0; }
    .s-mt-half { margin-top: $axis-y / 2; }
    .s-mb-half { margin-bottom: $axis-y / 2; }
    @for $i from 0 through 12 {
        @if $i < 6 {
            .s-p#{$i} { padding: $axis-y * $i $axis-x * $i; }
            .s-px#{$i} {
                padding-left: $axis-x * $i;
                padding-right: $axis-x * $i;
            }
            .s-pl#{$i} { padding-left: $axis-x * $i; }
            .s-pr#{$i} { padding-right: $axis-x * $i; }
            .s-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .s-pt#{$i} { padding-top: $axis-y * $i; }
            .s-pb#{$i} { padding-bottom: $axis-y * $i; }
            .s-mt#{$i} { margin-top: $axis-y * $i; }
            .s-mb#{$i} { margin-bottom: $axis-y * $i; }
        }
        @else {
            .s-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .s-pt#{$i} { padding-top: $axis-y * $i; }
            .s-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
    }
    .col-sm-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        max-width: auto;
    }
}
@media screen and (min-width: 768px) {
    .m-w033 { max-width: 330px; }
    .m-w039 { max-width: 390px; }
    .m-w041 { max-width: 410px; }
    .m-w046 { max-width: 460px; }
    .m-w054 { max-width: 540px; }
    .m-w071 { max-width: 700px; }
    .mb-w50p { max-width: none; }
    .m-m0 { margin:0; }
    .m-ml0 { margin-left: 0; }
    .m-mr0 { margin-right: 0; }
    .m-mt-half { margin-top: $axis-y / 2; }
    .m-mb-half { margin-bottom: $axis-y / 2; }
    .m-ml-auto { margin-left: auto; }
    .m-mr-auto { margin-right: auto; }
    .m-pr-half { padding-right: $axis-x / 2; }
    .min-md-h4 { min-height: 400px; }
    .m-col-r { margin-left: auto; margin-right: 0; }
    .m-p3-4 { padding: 0 0 4 / 3 * 100%; }
    @for $i from 1 through 8 {
        .m-my#{$i} {
            margin-top: $axis-y * $i;
            margin-bottom: $axis-y * $i;
        }
        .m-mt#{$i} { margin-top: $axis-y * $i; }
        .m-mb#{$i} { margin-bottom: $axis-y * $i; }
        .m-pl#{$i} {padding-left: $axis-x * $i;}
        .m-pr#{$i} {padding-right: $axis-x * $i;}
    }
    @for $i from 0 through 12 {
        @if $i < 5 {
            .m-p#{$i} { padding: $axis-y * $i $axis-x * $i; }
            .m-px#{$i} {
                padding-left: $axis-x * $i;
                padding-right: $axis-x * $i;
            }
            .m-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .m-pt#{$i} { padding-top: $axis-y * $i; }
            .m-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
        @else {
            .m-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .m-pt#{$i} { padding-top: $axis-y * $i; }
            .m-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
    }
    .m-px2-5 {
        padding-left: 25px;
        padding-right: 25px;
    }
    .col-md-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        max-width: auto;
    }
}
@media screen and (min-width: 769px) {
    .ml-w041 { max-width: 410px; }
    .ml-w047 { max-width: 470px; }
    .ml-w07 { max-width: 700px; }
    .ml-col-r { margin-left: auto; margin-right: 0; }
    @for $i from 0 through 8 {
        .ml-my#{$i} {
            margin-top: $axis-y * $i;
            margin-bottom: $axis-y * $i;
        }
        .ml-mt#{$i} { margin-top: $axis-y * $i; }
        .ml-mb#{$i} { margin-bottom: $axis-y * $i; }
        .ml-pl#{$i} {padding-left: $axis-x * $i;}
        .ml-pr#{$i} {padding-right: $axis-x * $i;}
    }
    @for $i from 0 through 12 {
        @if $i < 7 {
            .ml-p#{$i} { padding: $axis-y * $i $axis-x * $i; }
            .ml-px#{$i} {
                padding-left: $axis-x * $i;
                padding-right: $axis-x * $i;
            }
            .ml-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .ml-pt#{$i} { padding-top: $axis-y * $i; }
            .ml-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
        @else {
            .ml-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .ml-pt#{$i} { padding-top: $axis-y * $i; }
            .ml-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
    }
    .col-ml-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-ml-auto {
        flex: 0 0 auto;
        max-width: auto;
    }
}
@media screen and (min-width: 1200px) {
    .l-m0 { margin:0; }
    .l-ml0 { margin-left: 0; }
    .l-mr0 { margin-right: 0; }
    .l-w045 { max-width: 450px; }
    .l-pt2-half { padding-top: $axis-y * 2 + $axis-y / 2; }
    @for $i from 0 through 12 {
        @if $i < 7 {
            .l-my#{$i} {
                margin-top: $axis-y * $i;
                margin-bottom: $axis-y * $i;
            }
            .l-mt#{$i} { margin-top: $axis-y * $i; }
            .l-mb#{$i} { margin-bottom: $axis-y * $i; }
            .l-p#{$i} { padding: $axis-y * $i $axis-x * $i; }
            .l-px#{$i}, .lx#{$i} {
                padding-left: $axis-x * $i;
                padding-right: $axis-x * $i;
            }
            .l-pl#{$i}, .ll#{$i} { padding-left: $axis-x * $i; }
            .l-pr#{$i}, .lr#{$i} { padding-right: $axis-x * $i; }
            .l-py#{$i}, .ly#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .l-pt#{$i}, .lt#{$i} { padding-top: $axis-y * $i; }
            .l-pb#{$i}, .lb#{$i} { padding-bottom: $axis-y * $i; }
        }
        @else {
            .l-py#{$i}, .ly#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .l-pt#{$i}, .lt#{$i} { padding-top: $axis-y * $i; }
            .l-pb#{$i}, .lb#{$i} { padding-bottom: $axis-y * $i; }
        }
    }
    .col-lg-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        max-width: auto;
    }
}
@media screen and (min-width: 1400px) {
    .x-pl-half { padding-left: $axis-x / 2; }
    .x-pr-half { padding-right: $axis-x / 2; }
    .x-p-half { padding: $axis-y / 2 $axis-x / 2; }
    .x-px1-half {
        padding-left: $axis-x * 1.5;
        padding-right: $axis-x * 1.5;
    }
    .x-pl1-half { padding-left: $axis-x * 1.5; }
    .x-pr1-half { padding-right: $axis-x * 1.5; }
    @for $i from 0 through 12 {
        @if $i < 7 {
            .x-p#{$i} { padding: $axis-y * $i $axis-x * $i; }
            .x-px#{$i}, .xx#{$i} {
                padding-left: $axis-x * $i;
                padding-right: $axis-x * $i;
            }   
            .x-pl#{$i}, .xl#{$i} { padding-left: $axis-x * $i; }
            .x-pr#{$i}, .xr#{$i} { padding-right: $axis-x * $i; }
            .x-py#{$i}, .xy#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .x-pt#{$i} { padding-top: $axis-y * $i; }
            .x-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
        @else {
            .x-py#{$i} {
                padding-top: $axis-y * $i;
                padding-bottom: $axis-y * $i;
            }
            .x-pt#{$i} { padding-top: $axis-y * $i; }
            .x-pb#{$i} { padding-bottom: $axis-y * $i; }
        }
    }
    .col-xl-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        max-width: auto;
    }
}