/** plugin */
.swiper-sec {
    position: relative;
    overflow: hidden;
    a { text-decoration: none; }
    .slide {
        box-sizing: border-box;
        overflow: hidden;
    }
    .swiper-pagination-bullet {
        position: relative;
        @include opacity(1);
        @include radius(50%);
        vertical-align: middle;
        background: #ccc;
        transition: all .3s;
        &.on {
            background: $c1;
            border-color: transparent;
        }
    }
    .swiper-button-disabled { @include opacity(.5); }
    .swiper-pagination {
        position: absolute;
        left: 0;  bottom: 20px;
        
        width: 100%;
        .swiper-pagination-bullet {
            width: 10px; height: 10px;
            margin: 0 5px;
            &.on { width: 15px; height: 15px; }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        background: none;
        transform: translate(0, -50%);
        width: 40px; height: 40px;
        &:after {
            @include abs_half;
            content: ''; display: block;
            border: solid $cw;
            border-width: 0 2px 2px 0;
            padding: 15px;
        }
    }
    .swiper-button-prev {
        left: 4%;
        &:after {
            margin: 0 0 0 10px;
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    .swiper-button-next {
        right: 4%;
        &:after {
            margin: 0 0 0 -10px;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
.swiper { overflow: hidden; }
.slide { box-sizing: border-box; }
.sw-btn {
    width: 30px; height: 30px;
    margin: 0;
    background: none;
    transform: translate(0, -50%);
    &:after {
        @include abs_half;
        content: ''; display: block;
        border: solid #555;
        border-width: 0 2px 2px 0;
        padding: 6px;
        transform: scale(.7);
    }
}

.sw-arrow1, .sw-arrow2 {
    .sw-btn {
        background-color: $cw;
        padding: 0;
        &:after {
            border-color: #a2a2a2;
            padding: 4px;
        }
        @include hover {
            background-color: $c1;
            &:after { border-color: $cw; }
        }
    }
    .swiper-button-prev {
        left: 0;
        &:after {
            margin: 0 0 0 2px;
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    .swiper-button-next {
        right: 0;
        &:after {
            margin: 0 0 0 -2px;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
.sw-arrow2 {
    .sw-btn {
        left: auto;
        transform: none;
        &:first-child { margin-right: 10px; }
    }
    .bd {
        height: 2px;
        margin: 0 15px;
        background-color: #A0A4A8;
    }
    .swiper-pagination {
        position: static;
        left: auto; bottom: auto;
        transform: none;
    }
}
.sw1 {
    .swiper-pagination { bottom: 10px; }
}
.sw3, .sw3-1 { 
    .figure {
        &:after {
            @include abs_full;
            content: ''; display: block;
            border: 3px solid $c1;
            opacity: 0;
        }
    }
}
.sw3-1 {
    padding: 30px 10px;
    .swiper-slide { width: 33.33333%; }
    .slide { padding: 5px 10px; }
    .figure { cursor: pointer; }
    .swiper-slide-thumb-active {
        .figure:after { opacity: 1; }
    }
    .swiper-button-prev { left: 0; }
    .swiper-button-next { right: 0; }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 576px) {
    .sw5-1 {
        .swiper-slide { width: 25%; }
    }
    .sw-arrow1, .sw-arrow2 {
        .sw-btn { width: 40px; height: 40px; }
    }
    
}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 641px) {
    .sw1 {
        .figure {
            padding: 0 0 33.85416%;
            max-height: 712px;
        }
    }
}
@media screen and (min-width: 768px) {
    .swiper-sec {
        .swiper-button-prev, .swiper-button-next {
            width: 40px; height: 40px;
            &:after { padding: 18px; }
        }
        .swiper-button-prev:after { margin: 0 0 0 10px; }
        .swiper-button-next:after { margin: 0 0 0 -10px; }
    }
    .sw3-1 {
        padding: 35px 0;
        .slide { padding: 5px 20px; }
    }
}
@media screen and (min-width: 769px) {
    .sw1 {
        .swiper-pagination { bottom: 20px; }
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1400px) {}
@media screen and (min-width: 1600px) {}